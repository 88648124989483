@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif !important;
}

.HideScrollBar::-webkit-scrollbar {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

::-webkit-scrollbar:vertical {
  display: none;
}

/* .hideVerticle::-webkit-scrollbar:vertical {
  display: none;
} */


::-webkit-scrollbar-track {
  /* background: #7AACFF30; */
  border-radius: 10px;
  margin: 2rem 0;
}

::-webkit-scrollbar-thumb {
  background: #7AACFF;
  border-radius: 10px;
}

.MuiDataGrid-cell:focus {
  /* outline: none !important; */
}

/* 
.displayScrollBar ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.hideScrollBar ::-webkit-scrollbar {
  display: none !important;
  height: 0;
  width: 0;
} */